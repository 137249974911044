<template>
  <div class="ChargeConsumption page">
    <div class="top">
      <a-date-picker
        valueFormat="YYYY/MM/DD"
        v-model:value="value"
        @change="dateChange"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      @change="pageChange"
      :pagination="{
        current: page,
        total: total,
        pageSize: pageSize,
        showSizeChanger: true,
        showTotal: (total) => `总共 ${total} 条`,
        pageSizeOptions: ['20', '50', '100'],
      }"
    >
      <template #create_time="{ record }">
        {{ formatDate(record.create_time) }}
      </template>

      <template #is_fee="{ record }">
        {{ record.is_fee === "1" ? "是" : "否" }}
      </template>
      <!-- <template #operation="{ record }">
        <a-button size="small" type="primary" @click="handleEdit(record)"
          >编辑</a-button
        >
        <a-button size="small" style="margin-left: 4px">删除</a-button>
      </template> -->
    </a-table>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { chargeConsumption } from "../../service/finace";
import { formatDate } from "../../utils/function";
const columns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "终端ID",
    dataIndex: "user_id",
  },
  // {
  //   title: "代理商ID",
  //   dataIndex: "admin_id",
  // },
  {
    title: "ASR消费次数",
    dataIndex: "asr_num",
  },

  {
    title: "话费消费金额",
    dataIndex: "spend_num",
  },
  // {
  //   title: "短信消费金额",
  //   dataIndex: "sms_num",
  // },

  {
    title: "剩余ASR",
    dataIndex: "after_asr",
  },
  {
    title: "剩余话费",
    dataIndex: "after_spend",
  },
  // {
  //   title: "剩余短信",
  //   dataIndex: "after_sms",
  // },
  {
    title: "消费时间",
    dataIndex: "create_time",
    slots: {
      customRender: "create_time",
    },
  },
  {
    title: "任务ID",
    dataIndex: "job_id",
  },
  // {
  //   title: "通话id",
  //   dataIndex: "number_id",
  // },
  {
    title: "是否计费",
    dataIndex: "is_fee",
    slots: {
      customRender: "is_fee",
    },
  },
];
export default {
  name: "ChargeConsumption",
  setup() {
    const state = reactive({
      data: [],
      total: undefined,
      page: 1,
      pageSize: 20,
      value: undefined,
    });
    onMounted(() => {
      state.value = handleFun(Date.now());
      init();
    });
    function handleFun(ts) {
      var now = new Date(parseInt(ts));
      var year = now.getFullYear();
      var month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;
      var date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      return year + "/" + month + "/" + date;
    }
    const init = async () => {
      const res = await chargeConsumption({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        limit: state.pageSize,
        create_time: state.value,
      });
      console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const pageChange = (e) => {
      state.page = e.current;
      state.pageSize = e.pageSize;
      init();
    };
    const dateChange = (e) => {
      console.log(e);
      state.value = e;
      init();
    };
    return { columns, ...toRefs(state), pageChange, formatDate, dateChange };
  },
};
</script>

<style scoped>
.ChargeConsumption {
  padding: 10px;
  overflow: auto;
}
.top {
  margin-bottom: 15px;
}
.top .ant-input,
.top .ant-select {
  width: 180px;
  margin-right: 20px;
}
.top .ant-btn {
  margin-right: 20px;
}
</style>