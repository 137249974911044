import http from "../axios/http"

//ASr充值记录
export let getAsrList = (params) => {
    return http.get("/finance/asr_list", { params })
}
//终端话费充值
export let getChargeList = (params) => {
    return http.get("/finance/charge_list", { params })
}
//消费记录asr列表
export let asrConsumption = (params) => {
    return http.get("/finance/spend", { params })
}
//消费记录花费列表
export let chargeConsumption = (params) => {
    return http.get("/finance/consumption", { params })
}
//消话费记录列表
export let getChargeListIndex = (params) => {
    return http.get("/finance/charge_index", { params })
}
//话费、asr、短信充值
export let postChongzhi = (params) => {
    return http.post("/finance/charge", params)
}
